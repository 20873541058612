<template>
  <div>
    <vx-card class="form-title mb-6" title="SELECT">
      <form>
        <div class="row mb-4">
          <div class="col-6 mb-2">
            <h6>Company Name</h6>
            <multiselect
              v-model="form.companySelected"
              track-by="company_id"
              label="company_name"
              :options="companies"
              name="Company Name"
              placeholder="Select Company"
              :searchable="true"
              :allow-empty="true"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                option.company_name
                }}
              </template>
            </multiselect>
            <p class="error-msg">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Company Name')"
              >{{ errors.first("Company Name") }}</span>
            </p>
          </div>

          <div class="col-6 mb-2">
            <h6>Project Name</h6>
            <!-- <vs-input
              type="text"
              name="Project Name"
              v-model="form.projectSelected"
              class="w-full"
            />
            <p class="error-msg text-sm">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Project Name')"
              >{{ errors.first("Project Name") }}</span>
            </p>-->

            <multiselect
              v-model="form.projectSelected"
              track-by="project_id"
              label="project_name"
              :options="projects"
              name="Project Name"
              placeholder="Select Project"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                option.project_name
                }}
              </template>
            </multiselect>
            <p class="error-msg">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Project Name')"
              >{{ errors.first("Project Name") }}</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-2">
            <h6>Type of Agreement</h6>
            <multiselect
              label="name"
              class="w-full"
              track-by="id"
              v-model="form.typeOfAgreement"
              name="contract Form"
              :options="formType"
              :searchable="true"
              :allow-empty="false"
              placeholder="Select Option"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                option.name
                }}
              </template>
            </multiselect>
            <p class="error-msg text-sm">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Vender Name')"
              >{{ errors.first("Vender Name") }}</span>
            </p>
          </div>

          <div class="col-6 mb-2">
            <h6>Select Date</h6>
            <div class="d-flex align-items-center">
              <div class="w-100 mr-2">
                <datepicker placeholder="From Date" format="dd-MM-yyyy" v-model="form.dateFrom"></datepicker>
                <!-- :disabledDates="startDisabledDates"   @selected="onSelectDate($event)"-->
              </div>
              <div class="w-100 ml-2">
                <datepicker
                  placeholder="To Date"
                  :disabledDates="endDisabledDates"
                  v-model="form.dateTo"
                  format="dd-MM-yyyy"
                  :disabled="toDateDisable"
                ></datepicker>
              </div>
            </div>
          </div>
        </div>

        <!-- SUBMIT BUTTON -->
        <div class="vx-row">
          <div class="vx-col mx-auto">
            <!-- :disabled="submitStatus" -->
            <vs-button type="filled" @click.prevent="submitForm" class="mr-3">Submit</vs-button>

            <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button>
          </div>
        </div>
      </form>
    </vx-card>

    <vx-card class="form-title" title="LIST">
      <div class="row mb-2" v-if="this.fieldsMileStoneItem.length>0">
        <div class="col-10"></div>
        <div class="col-2 pl-5">
          <vx-tooltip text="Export">
            <vs-button class="px-4 mr-3" @click="ExportExcel">
              <span class="d-inline-flex pr-5 text-white">Export</span>
              <img
                src="@/assets/images/icons/export-excel.svg"
                class="d-inline-flex"
                height="20"
                width="20"
              />
            </vs-button>
          </vx-tooltip>
        </div>
      </div>
      <div>
        <div class="row" v-if="fieldsMileStoneItem.length>0">
          <div class="col-4 pr-0 chart-table chart-table-data chart-table-left">
            <!-- :small="small" -->
            <b-table
              :sticky-header="stickyHeader"
              :no-border-collapse="noCollapse"
              responsive
              :bordered="bordered"
              :items="fieldsMileStoneItem"
              :fields="fieldsMileStone"
            >
              <!-- used for top space -->

              <template v-slot:thead-top="data">
                <div style="opacity:0">
                  <b-tr>
                    <b-th
                      variant="secondary"
                      style="text-align:center;border-right:1px"
                      v-for="(month,monthIndex) in fieldsMileStone"
                      :key="monthIndex"
                    >Nov</b-th>
                    <!-- <b-th variant="primary" colspan="4">Type</b-th>
                    <b-th variant="danger">Type</b-th>    :colspan="month.no_of_days"-->
                  </b-tr>
                  <b-tr>
                    <!-- <div v-for="(month,monthIndex1) in ListMonth.monthArray" :key="monthIndex1"> -->
                    <b-th
                      variant="secondary"
                      v-for="(month,monthIndex) in fieldsMileStone"
                      :key="monthIndex"
                    >12</b-th>
                    <!-- </div> -->
                    <!-- <b-th variant="primary">Type</b-th>
                    <b-th variant="danger">Type</b-th>-->
                  </b-tr>
                </div>
              </template>

              <template v-slot:cell(vendor_name)="data">
                <div
                  class="text-nowrap"
                  @click="showModal(data.item)"
                  style="cursor:pointer;"
                >{{ data.item.vendor_name}}</div>
              </template>
              <!-- used for top space -->
              <template v-slot:head()="scope">
                <div class="text-nowrap">{{ scope.label }}</div>
              </template>
            </b-table>
          </div>
          <div class="col-8 pl-0 chart-table chart-table-data chart-table-right">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th
                      class="text-center"
                      scope="col"
                      v-for="(month,monthIndex) in ListMonth.monthArray"
                      :key="monthIndex"
                      :colspan="month.no_of_days"
                    >{{month.no_of_days==1?"":month.month}}</th>
                  </tr>
                  <tr>
                    <th
                      scope="col"
                      v-for="(datesAll,dateindex) in ListMonth.allDates"
                      :key="dateindex"
                    >{{dateindex==0 || ListMonth.allDates.length-1==dateindex?"":datesAll.day}}</th>
                  </tr>
                  <tr>
                    <th
                      scope="col"
                      v-for="(day,dayindex) in fields"
                      :key="dayindex"
                    >{{dayindex==0 || fields.length-1==dayindex?"":day.label}}</th>
                  </tr>
                </thead>

                <tbody>
                  <!-- USER FOR LOOP IN 'TR' TAG FOR TABLE ROWS -->
                  <tr v-for="(item, itemIndex) in items" :key="itemIndex">
                    <!-- USER FOR LOOP IN 'TD' TAG FOR TABLE COL -->
                    <td
                      v-for="(item1, itemIndex1) in Object.keys(item)"
                      :key="itemIndex1"
                      :style="items[itemIndex][item1]==1?'border-right: 1px solid '+fieldsMileStoneItem[itemIndex].status_color+' !important':''"
                    >
                      <span
                        :class="items[itemIndex][item1]==1?'fieldsMileStoneItemFillData':'fieldsMileStoneItemEmptyData'"
                        :style="items[itemIndex][item1]==1?'background-color: '+fieldsMileStoneItem[itemIndex].status_color+' !important;border-right: 1px solid  '+fieldsMileStoneItem[itemIndex].status_color+'  !important':'background-color:#fff;'"
                      >
                        <span style="opacity : 0">{{items[itemIndex][item1]==1}}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- ******************************** -->
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center vh-75" v-else>
          <p>No Data Found</p>
        </div>
        <div class="mt-3">
          <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
        </div>
      </div>
      <!-- -->
    </vx-card>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index.js";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import ContractService from "@/services/ContractService.js";
import projectService from "@/services/projectService.js";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      projects: [],
      totalPages: 0,
      maxPageNumbers: 7,
      companies: [],
      formType: [
        { name: "MOU", id: "1" },
        { name: "LFA", id: "2" },
        { name: "FLA", id: "3" },
        { name: "FLA Acquisition", id: "4" },
      ],
      startDisabledDates: {
        from: new Date(),
      },
      endDisabledDates: {
        from: null,
        to: new Date(),
      },
      form: {
        projectSelected: "",
        companySelected: "",
        dateFrom: "",
        dateTo: "",
        typeOfAgreement: "",
      },
      toDateDisable: true,
      stickyHeader: true,
      noCollapse: false,
      bordered: true,
      detailsShowing: true,
      small: true,

      fieldsMileStoneItem: [],
      fieldsMileStone: [
        {
          key: "vendor_name",
          label: "Vendor name as per contract",
          sortable: true,
        },
        {
          key: "contract_description",
          label: "Description",
          sortable: true,
        },
        {
          key: "sub_category_name",
          label: "Category",
          sortable: true,
        },
        {
          key: "assignees",
          label: "Contract Assigned To",
          sortable: true,
        },

        {
          key: "value_number",
          label: "Value Number",
          sortable: true,
        },
        {
          key: "remainingDays",
          label: "No. of days remaining",
          sortable: true,
        },
        {
          key: "paid_amount",
          label: "Paid Amount",
          sortable: true,
        },
        {
          key: "startDate",
          label: "Start Date ",
          sortable: true,
        },
        {
          key: "due_date",
          label: "Due Date ",
          sortable: true,
        },
      ],
      fields: [],
      items: [],
      ListMonth: [],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
      }
    },
  },
  computed: {
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          // if (val > 1) {
          //   this.getGrantChart(val);
          // }
          this.getGrantChart(val);
          return val;
        } else return 1;
      },
    },
  },
  beforeMount() {
    this.getAgreement();
    this.getCompanyList();
    this.getProjectsList();

    // this.getContractProjectList();
    // this.getGrantChart(this.currentPage);
    let date = new Date().getFullYear();
    let month = new Date().getMonth();

    // moment(currentDate).add(1, "days");
    this.ListMonth = this.getDates(
      moment(`${date}-${month}-01`).subtract(1, "d"),
      moment(`${date + 1}-03-31`).add(1, "d")
    );
    this.form.dateFrom = `${date}-${month}-01`;
    this.form.dateTo = `${date + 1}-03-31`;

    this.toDateDisable = false;
  },
  methods: {
    ExportExcel() {
      let organization_id = window.localStorage.getItem("OrganizationID");
      let temp_token = window.localStorage.getItem("TempToken");
      let filters = {
        page: this.currentPage,
      };

      if (this.form.typeOfAgreement.hasOwnProperty("contract_type")) {
        filters["contractType"] = this.form.typeOfAgreement.contract_type;
      }

      if (this.form.companySelected != "") {
        if (this.form.companySelected.hasOwnProperty("company_id")) {
          filters["company_id"] = this.form.companySelected.company_id;
        }
      }

      if (
        this.form.projectSelected != "" &&
        this.form.projectSelected.hasOwnProperty("project_id")
      ) {
        filters["project"] = this.form.projectSelected.project_id;
      }

      this.form.dateFrom = moment(this.form.dateFrom).format("YYYY-MM-DD");
      this.form.dateTo = moment(this.form.dateTo).format("YYYY-MM-DD");

      let url = `${BASEURL}/v2/formFla/graphExcel/download?organization_id=${organization_id}&token=${temp_token}&dateFrom=${this.form.dateFrom}&dateTo=${this.form.dateTo}&type=${this.form.typeOfAgreement.id}`;
      Object.keys(filters).map((x) => {
        url += "&" + x + "=" + filters[x];
      });

      //  console.log(url);
      //  return false
      window.open(url);
    },
    getProjectsList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showModal: function (contractData) {
      // console.log(contractData);
      // return false;
      let data = {
        tabIndex: this.form.typeOfAgreement.contract_type == 1 ? "0" : "1",
        ID:
          this.form.typeOfAgreement.contract_type == 1
            ? contractData.mou_id
            : contractData.fla_id,
        tabIndexView: "1",
      };
      // console.log("data FLAAAA :>> ", data);
      eventBus.$emit("switchFormEditTabView", data);
      if (this.form.typeOfAgreement.contract_type == 1) {
        setTimeout(() => {
          eventBus.$emit("switchFormEditTab", data);
        }, 1000);
      } else {
        setTimeout(() => {
          eventBus.$emit("switchFormEditTabFla", data);
        }, 1000);
      }
    },
    getCompanyList: function () {
      let filters = {
        contractType: "Company",
      };
      // if (this.archive == "archive") {
      //   if (this.designations.length == 1) {
      //     this.designations = [];
      //   }

      //   filter["archive"] = true;
      // }
      ContractService.getContractVendorListMou(filters)
        .then((response) => {
          const { data } = response;
          //  console.log(data);
          if (!data.err) {
            this.companies = data.data;

            // this.totalPages = data.pagination.last_page;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAgreement: function () {
      let filters = {
        contractType: "Agreement",
      };
      // if (this.archive == "archive") {
      //   if (this.designations.length == 1) {
      //     this.designations = [];
      //   }

      //   filter["archive"] = true;
      // }
      ContractService.getContractVendorListMou(filters)
        .then((response) => {
          const { data } = response;
          //  console.log(data);
          if (!data.err) {
            this.formType = data.data;

            let array = data.data.filter((x) => {
              return x.contract_type == 1;
            });
            if (array.length > 0) {
              this.form.typeOfAgreement = array[0];
            } else {
              this.form.typeOfAgreement = data.data[0];
            }

            this.getGrantChart(this.currentPage);

            // this.totalPages = data.pagination.last_page;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGrantChart: function (currentPage) {
      this.items = [];
      this.fieldsMileStoneItem = [];
      this.$vs.loading();

      if (this.form.typeOfAgreement == "") {
        return false;
      }
      let filters = {
        id: this.form.typeOfAgreement.id,
        page: currentPage,
      };

      if (this.form.typeOfAgreement.hasOwnProperty("contract_type")) {
        filters["contractType"] = this.form.typeOfAgreement.contract_type;
      }

      if (this.form.companySelected != "") {
        if (this.form.companySelected.hasOwnProperty("company_id")) {
          filters["company_id"] = this.form.companySelected.company_id;
        }
      }

      if (
        this.form.projectSelected != "" &&
        this.form.projectSelected.hasOwnProperty("project_id")
      ) {
        filters["project"] = this.form.projectSelected.project_id;
      }
      //  return false;

      ContractService.getGrantChart(filters)
        .then((response) => {
          const { data } = response;
          // console.log(data);
          if (data.hasOwnProperty("data") && data.data.length > 0) {
            this.fieldsMileStoneItem = data.data;
            this.totalPages = data.pagination.last_page;
            //    return false;
            let count = 0;
            this.fieldsMileStoneItem.map((x, index) => {
              let objItem = {};
              let arrayNew = this.getDatesArray(x.startDate, x.due_date);
              console.log(arrayNew);
              this.fields.map((y) => {
                let checkIndex = arrayNew.allDates.findIndex((z) => {
                  return z.dates == y.key;
                });

                if (checkIndex >= 0) {
                  objItem[y.key] = 1;
                } else {
                  objItem[y.key] = 0;
                }
              });
              console.log(objItem, count++);
              this.items.push(objItem);
            });

            // this.totalPages = data.pagination.last_page;
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDatesArray(startDate, stopDate) {
      var dateArray = [];
      var MonthArray = [];
      var currentDate = moment(startDate);
      var stopDate = moment(stopDate);
      while (currentDate <= stopDate) {
        let obj = {
          dates: moment(currentDate).format("YYYY-MM-DD"),
          dayName: moment(currentDate).format("dddd"),
          day: moment(currentDate).format("DD"),
          month: moment(currentDate).format("MMMM YYYY"),
        };

        dateArray.push(obj);
        currentDate = moment(currentDate).add(1, "days");
      }

      let objfinal = {
        allDates: dateArray,
      };
      return objfinal;
    },
    getDates(startDate, stopDate) {
      var dateArray = [];
      var MonthArray = [];
      var currentDate = moment(startDate);
      var stopDate = moment(stopDate);
      while (currentDate <= stopDate) {
        let obj = {
          dates: moment(currentDate).format("YYYY-MM-DD"),
          dayName: moment(currentDate).format("dddd"),
          day: moment(currentDate).format("DD"),
          month: moment(currentDate).format("MMMM YYYY"),
        };

        let findIndew2x = MonthArray.findIndex((x) => {
          return x.month == moment(currentDate).format("MMMM YYYY");
        });
        if (findIndew2x < 0) {
          let obj2 = {
            month: moment(currentDate).format("MMMM YYYY"),
            no_of_days: 1,
          };
          MonthArray.push(obj2);
        } else {
          MonthArray[findIndew2x]["no_of_days"] =
            MonthArray[findIndew2x]["no_of_days"] + 1;
        }

        let fieldsObj = {
          key: moment(currentDate).format("YYYY-MM-DD"),
          label: moment(currentDate).format("dddd").charAt(0),
          // sortable: true
        };

        let x = moment(currentDate).format("YYYY-MM-DD");

        //  this.items[0][x] = "1";
        this.fields.push(fieldsObj);

        dateArray.push(obj);
        currentDate = moment(currentDate).add(1, "days");
      }

      let objfinal = {
        allDates: dateArray,
        monthArray: MonthArray,
      };
      return objfinal;
    },
    onSelectDate: function ($event) {
      this.endDisabledDates["to"] = new Date(moment(new Date($event)).format());
      this.form.dateTo = new Date($event);
      // this.form.dateTo = moment(this.form.dateTo).add(30, "days");
      this.toDateDisable = false;
    },
    submitForm: function () {
      this.$vs.loading();
      this.fields = [];

      this.$vs.loading.close();

      this.ListMonth = this.getDates(
        moment(this.form.dateFrom).subtract(1, "d"),
        moment(this.form.dateTo).add(1, "d")
      );
      this.fieldsMileStoneItem = [];
      this.items = [];
      this.getGrantChart(this.currentPage);
    },
    clearForm: function () {
      this.form = {
        projectSelected: "",
        companySelected: "",
        dateFrom: "",
        dateTo: "",
        typeOfAgreement: "",
      };
      this.currentPage = 1;
      this.getAgreement();
    },
  },
};
</script>

<style>
</style>
